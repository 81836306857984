import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import SwiperInstance from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import ButtonWithIcon from 'components/shared/button-with-icon'
import LazyImage from 'components/shared/lazy-image'
import Icon from 'components/shared/icon'

import media from 'styles/media'
import 'swiper/css'

import arrowIcon from 'assets/icons/arrow-right-black.svg'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  ${media.lg.min} {
    padding: 30px 0 20px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
`

const SliderWrapper = styled.div`
  position: relative;
  height: 454px;
  border: 1px solid #c6c6c6;
  margin-bottom: 40px;

  .swiper {
    height: 100%;
  }

  ${media.lg.min} {
    height: 601px;
  }
`

const ControlButton = styled.button`
  position: absolute;
  z-index: 2;
  border: 1px solid ${({ theme }) => theme.colors.black};
  width: 40px;
  height: 40px;
  background: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
  }
`

const SlideLeftButton = styled(ControlButton)`
  left: 20px;
  bottom: 20px;

  img {
    transform: rotate(180deg);
  }
`

const SlideRightButton = styled(ControlButton)`
  right: 20px;
  bottom: 20px;
`

const SlideImageWrapper = styled.div`
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const InfoWrapper = styled.div`
  ${media.lg.min} {
    width: 380px;
    margin: 0 74px;
  }
`

const PriceBoxWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.black};

  button {
    width: 100%;
  }
`

const PriceWrapper = styled.div`
  padding: 18px 22px;

  ${Text}:last-child {
    color: ${({ theme }) => theme.colors.red};
  }

  ${media.lg.min} {
    ${Text}:first-child {
      font-size: 22px;
    }
  }
`

const ChooseColorWrapper = styled.div`
  padding: 40px 0;
`

const ColorVariantTilesWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 16px;
`

const ColorVariantTileWrapper = styled.div<{ active: boolean }>`
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.black};
  border-width: ${({ active }) => (active ? '3px' : '1px')};
  cursor: pointer;
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.lg.min} {
    width: 57px;
    height: 57px;
  }
`

const CompatibleModelsWrapper = styled.div`
  padding-bottom: 40px;

  button {
    width: 100%;
    border-top: none;
    border-bottom: 1px solid;
    border-left: none;
    border-right: none;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    &:hover {
      background-color: initial;
      color: initial;
      border-color: initial;

      img {
        filter: invert();
      }
    }
    &:nth-of-type(1) {
      border-top: 1px solid;
    }
  }
`

const DownloadsWrapper = styled.div``

type Props = {
  title: string
  priceText: string
  promoText: string
  colorVariants: Array<{
    colorName: string
    images: Array<{
      src: ImageDataLike
      alt: string
    }>
  }>
  compatibleModels: Array<{
    name: string
    link: string
  }>
  downloads: Array<{
    name: string
    link: string
  }>
}

const AccessoryHeader: React.FC<Props> = ({
  title,
  priceText,
  promoText,
  colorVariants,
  compatibleModels,
  downloads,
}) => {
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  const [currentColor, setCurrentColor] = useState(colorVariants[0])

  return (
    <Container slim>
      <Wrapper>
        <SliderWrapper>
          {currentColor.images.length > 1 && (
            <>
              <SlideLeftButton onClick={goPrev} disabled={activeIndex === 0}>
                <Icon src={arrowIcon} />
              </SlideLeftButton>
              <SlideRightButton
                onClick={goNext}
                disabled={
                  colorVariants[0]?.images
                    ? activeIndex === colorVariants[0].images.length - 1
                    : true
                }
              >
                <Icon src={arrowIcon} />
              </SlideRightButton>
            </>
          )}
          <Swiper
            onSwiper={(initSwiper: SwiperInstance) => setSwiper(initSwiper)}
            spaceBetween={0}
            slidesPerView={1}
            grabCursor
            onSlideChange={() => setActiveIndex(swiper?.realIndex ?? 0)}
          >
            {currentColor?.images.map((image, index) => (
              <SwiperSlide key={`${image.alt}-${index}`}>
                <SlideImageWrapper>
                  <LazyImage
                    src={image.src}
                    alt={image.alt}
                    objectFit="contain"
                  />
                </SlideImageWrapper>
              </SwiperSlide>
            ))}
          </Swiper>
        </SliderWrapper>
        <InfoWrapper>
          <Text
            dangerouslySetInnerHTML={{ __html: title }}
            size={36}
            weight={700}
            line="100%"
            margin="40px"
          />
          <PriceBoxWrapper>
            {priceText && (
              <PriceWrapper>
                <Text
                  dangerouslySetInnerHTML={{ __html: priceText }}
                  size={18}
                  align="center"
                  margin="0"
                />
                <Text
                  dangerouslySetInnerHTML={{ __html: promoText }}
                  size={16}
                  align="center"
                  margin="0"
                />
              </PriceWrapper>
            )}
            <ButtonWithIcon as={Link} to="/znajdz-dealera" width={100}>
              Zapytaj o produkt
            </ButtonWithIcon>
          </PriceBoxWrapper>
          {colorVariants.length > 1 && (
            <ChooseColorWrapper>
              <Text size={18} margin="0">
                Wybierz kolor / {currentColor.colorName}
              </Text>
              <ColorVariantTilesWrapper>
                {colorVariants.map((colorVariant) => (
                  <ColorVariantTileWrapper
                    key={colorVariant.colorName}
                    active={colorVariant.colorName === currentColor.colorName}
                    onClick={() => {
                      setCurrentColor(colorVariant)
                      swiper?.slideTo(0)
                    }}
                  >
                    <LazyImage
                      src={colorVariant?.images[0].src}
                      alt={colorVariant?.images[0].alt}
                      objectFit="contain"
                    />
                  </ColorVariantTileWrapper>
                ))}
              </ColorVariantTilesWrapper>
            </ChooseColorWrapper>
          )}
          {compatibleModels.length > 0 && (
            <CompatibleModelsWrapper>
              <Text size={16} margin="16px">
                Pasuje do modeli:
              </Text>
              {compatibleModels.map((model) => (
                <ButtonWithIcon white key={model.name}>
                  {model.name}
                </ButtonWithIcon>
              ))}
            </CompatibleModelsWrapper>
          )}
          {downloads.length > 0 && (
            <DownloadsWrapper>
              <Text size={16} margin="16px">
                Pliki do pobrania:
              </Text>
              {downloads.map((download) => (
                <ButtonWithIcon
                  width={100}
                  white
                  key={download.name}
                  icon="download"
                  as="a"
                  href={download.link}
                  target="_blank"
                >
                  {download.name}
                </ButtonWithIcon>
              ))}
            </DownloadsWrapper>
          )}
        </InfoWrapper>
      </Wrapper>
    </Container>
  )
}

export default AccessoryHeader
