// node modules
import React from 'react'
import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// sections

import Breadcrumbs from 'components/shared/breadcrumbs'
import AccessoryHeader from 'components/pages/accessory/accessory-header'
import AccessorySpecifications from 'components/pages/accessory/accessory-specifications'
import AccessoryInstallationGallery from 'components/pages/accessory/accessory-installation-gallery'
import MoreAccessories from 'components/pages/accessory/more-accessories'

import media from 'styles/media'

const PageBreadcrumbs = styled.div`
  padding-top: 80px;

  ${media.lg.min} {
    padding-top: 105px;
  }
`

const AccessoryPage: React.FC<PageProps<Queries.AccessoryPageQuery>> = ({
  data,
}) => {
  const PAGE = data.wpCfmotoAccessory?.cfmotoAccessory!
  const PAGE_SEO = data.wpCfmotoAccessory?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const COLOR_VARIANTS =
    PAGE.accessoryInfoColorsVariants?.map((variant) => ({
      colorName: variant?.accessoryInfoColorVariantsName!,
      images:
        variant?.accessoryInfoColorVariantsGallery?.map((image) => ({
          src: image?.accessoryInfoColorVariantsGalleryImage?.localFile
            ?.childImageSharp?.gatsbyImageData!,
          alt: image?.accessoryInfoColorVariantsGalleryImage?.altText!,
        })) || [],
    })) || []

  const COMPATIBLE_MODELS =
    PAGE.accessoryInfoCompatibleModels?.map((model) => ({
      name: model?.accessoryInfoCompatibleModelsName!,
      link: model?.accessoryInfoCompatibleModelsLink!,
    })) || []

  const DOWNLOADS =
    PAGE.accessoryInfoDownloads?.map((download) => ({
      name: download?.accessoryInfoDownloadsName!,
      link: download?.accessoryInfoDownloadsLink!,
    })) || []

  const FEATURES =
    PAGE.accessorySpecsFeatures?.map(
      (feature) => feature?.accessorySpecsFeature!
    ) || []

  const INSTALLATION_GALLERY =
    PAGE.accessoryInstallationGallery?.map((slide) => ({
      text: slide?.accessoryInstallationGalleryImageDescription!,
      image: {
        src: slide?.accessoryInstallationGalleryImage?.localFile
          ?.childImageSharp?.gatsbyImageData!,
        alt: slide?.accessoryInstallationGalleryImage?.altText!,
      },
    })) || []

  const OTHER_ACCESSORIES =
    data.allWpCfmotoAccessory?.nodes?.map((accessory) => ({
      name: accessory.title!,
      link: `/akcesoria/${accessory.slug}`,
      image: {
        src: accessory?.cfmotoAccessory?.accessoryInfoColorsVariants?.[0]
          ?.accessoryInfoColorVariantsGallery?.[0]
          ?.accessoryInfoColorVariantsGalleryImage?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: accessory?.cfmotoAccessory?.accessoryInfoColorsVariants?.[0]
          ?.accessoryInfoColorVariantsGallery?.[0]
          ?.accessoryInfoColorVariantsGalleryImage?.altText!,
      },
    })) || []

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <PageBreadcrumbs>
        <Breadcrumbs
          crumbs={[
            { name: 'Akcesoria', to: '/akcesoria' },
            { name: data.wpCfmotoAccessory.title! },
          ]}
        />
      </PageBreadcrumbs>
      <AccessoryHeader
        title={data.wpCfmotoAccessory.title!}
        priceText={PAGE.accessoryInfoPriceText!}
        promoText={PAGE.accessoryInfoPromoText!}
        colorVariants={COLOR_VARIANTS!}
        compatibleModels={COMPATIBLE_MODELS!}
        downloads={DOWNLOADS!}
      />
      <AccessorySpecifications
        productCode={PAGE.accessorySpecsProductCode!}
        image={{
          src: PAGE.accessorySpecsImage?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.accessorySpecsImage?.altText!,
        }}
        features={FEATURES!}
      />
      {INSTALLATION_GALLERY.length > 0 && (
        <AccessoryInstallationGallery slides={INSTALLATION_GALLERY!} />
      )}
      <MoreAccessories products={OTHER_ACCESSORIES} />
    </Layout>
  )
}

export default AccessoryPage

export const query = graphql`
  query AccessoryPage($slug: String) {
    wpCfmotoAccessory(slug: { eq: $slug }) {
      seo {
        ...WpSEO
      }
      title
      slug
      cfmotoAccessory {
        accessoryInfoPriceText
        accessoryInfoPromoText
        accessoryInfoColorsVariants {
          accessoryInfoColorVariantsName
          accessoryInfoColorVariantsGallery {
            accessoryInfoColorVariantsGalleryImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        accessoryInfoCompatibleModels {
          accessoryInfoCompatibleModelsName
          accessoryInfoCompatibleModelsLink
        }
        accessoryInfoDownloads {
          accessoryInfoDownloadsName
          accessoryInfoDownloadsLink
        }
        accessorySpecsProductCode
        accessorySpecsFeatures {
          accessorySpecsFeature
        }
        accessorySpecsImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        accessoryInstallationGallery {
          accessoryInstallationGalleryImageDescription
          accessoryInstallationGalleryImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allWpCfmotoAccessory(limit: 6, filter: { slug: { ne: $slug } }) {
      nodes {
        id
        title
        slug
        cfmotoAccessory {
          accessoryInfoColorsVariants {
            accessoryInfoColorVariantsGallery {
              accessoryInfoColorVariantsGalleryImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
