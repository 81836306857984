import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import SwiperInstance from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'
import ButtonWithIcon from 'components/shared/button-with-icon'
import Icon from 'components/shared/icon'

import media from 'styles/media'
import 'swiper/css'

import arrowIcon from 'assets/icons/arrow-right-black.svg'

import { ImageDataLike } from 'gatsby-plugin-image'

const HeadingWrapper = styled.div`
  padding: 100px 0 20px;
  border-bottom: 4px solid ${({ theme }) => theme.colors.black};

  ${media.lg.min} {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
`

const MoreAccessoriesButtonDesktop = styled.button`
  display: none;

  ${media.lg.min} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
    border: none;
    background: none;
    cursor: pointer;
  }
`

const InnerWrapper = styled.div`
  padding-bottom: 100px;
`

const MobileTilesWrapper = styled.div`
  padding: 20px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1px;
  row-gap: 20px;

  ${media.lg.min} {
    display: none;
  }
`

const DesktopSliderWrapper = styled.div`
  display: none;

  ${media.lg.min} {
    display: block;
    position: relative;
    padding: 30px 0 0;

    .swiper-slide {
      height: auto;
    }
  }
`

const ControlButton = styled.button`
  position: absolute;
  z-index: 2;
  border: 1px solid ${({ theme }) => theme.colors.black};
  width: 40px;
  height: 40px;
  background: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
  }
`

const SlideLeftButton = styled(ControlButton)`
  left: -60px;
  top: 50%;
  transform: translateY(-50%);

  img {
    transform: rotate(180deg);
  }
`

const SlideRightButton = styled(ControlButton)`
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
`

const ProductImageWrapper = styled.div`
  height: 167px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.lg.min} {
    height: 215px;
  }
`

const ProductInfoWrapper = styled.div`
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 8px 10px 8px;
  min-height: 50px;
  transition: 0.3s;
  flex-grow: 1;

  ${media.lg.min} {
    padding: 10px;
    min-height: unset;
  }
`

const ProductNameText = styled(Text)`
  font-size: 14px;
  margin: 0;
  text-align: center;

  ${media.lg.min} {
    font-size: 16px;
  }
`

const ProductTileWrapper = styled.div`
  border: 1px solid #ebebeb;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  height: 100%;

  &:hover {
    ${ProductInfoWrapper} {
      background-color: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
    }

    ${ProductNameText} {
      color: ${({ theme }) => theme.colors.white};

      span {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

const MobileButtonWrapper = styled.div`
  ${media.lg.min} {
    display: none;
  }
`

type Props = {
  products: Array<{
    name: string
    link: string
    image: {
      src: ImageDataLike
      alt: string
    }
  }>
}

const MoreAccessories: React.FC<Props> = ({ products }) => {
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  return (
    <Container slim>
      <HeadingWrapper>
        <Text
          size={26}
          weight={700}
          transform="uppercase"
          line="100%"
          margin="0"
        >
          Więcej akcesoriów
        </Text>

        <MoreAccessoriesButtonDesktop as={Link} to="/akcesoria">
          Wszystkie akcesoria
          <Icon src={arrowIcon} />
        </MoreAccessoriesButtonDesktop>
      </HeadingWrapper>
      <InnerWrapper>
        <MobileTilesWrapper>
          {products.map(({ name, link, image }, index) => (
            <ProductTileWrapper key={`${name}-${index}`} as={Link} to={link}>
              <ProductImageWrapper>
                <LazyImage
                  src={image.src}
                  alt={image.alt}
                  fill
                  objectFit="contain"
                />
              </ProductImageWrapper>
              <ProductInfoWrapper>
                <ProductNameText dangerouslySetInnerHTML={{ __html: name }} />
              </ProductInfoWrapper>
            </ProductTileWrapper>
          ))}
        </MobileTilesWrapper>
        <DesktopSliderWrapper>
          <SlideLeftButton onClick={goPrev} disabled={activeIndex === 0}>
            <Icon src={arrowIcon} />
          </SlideLeftButton>
          <SlideRightButton
            onClick={goNext}
            disabled={activeIndex + 3 === products.length - 1}
          >
            <Icon src={arrowIcon} />
          </SlideRightButton>
          <Swiper
            onSwiper={(initSwiper: SwiperInstance) => setSwiper(initSwiper)}
            spaceBetween={10}
            slidesPerView={4}
            grabCursor
            onSlideChange={() => setActiveIndex(swiper?.realIndex ?? 0)}
          >
            {products.map(({ name, link, image }, index) => (
              <SwiperSlide key={`${name}-${index}`}>
                <ProductTileWrapper as={Link} to={link}>
                  <ProductImageWrapper>
                    <LazyImage
                      src={image.src}
                      alt={image.alt}
                      fill
                      objectFit="contain"
                    />
                  </ProductImageWrapper>
                  <ProductInfoWrapper>
                    <ProductNameText
                      dangerouslySetInnerHTML={{ __html: name }}
                    />
                  </ProductInfoWrapper>
                </ProductTileWrapper>
              </SwiperSlide>
            ))}
          </Swiper>
        </DesktopSliderWrapper>
        <MobileButtonWrapper>
          <ButtonWithIcon white width={100} as={Link} to="/akcesoria">
            Wszystkie akcesoria
          </ButtonWithIcon>
        </MobileButtonWrapper>
      </InnerWrapper>
    </Container>
  )
}

export default MoreAccessories
