import React, { useState } from 'react'
import styled from 'styled-components'
import SwiperInstance from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'
import Icon from 'components/shared/icon'

import ModalGallery from 'components/shared/modal-gallery'

import media from 'styles/media'
import 'swiper/css'

import arrowIcon from 'assets/icons/arrow-right-black.svg'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div``

const HeadingWrapper = styled.div`
  padding: 100px 0 20px;
  border-bottom: 4px solid ${({ theme }) => theme.colors.black};

  ${media.lg.min} {
    padding: 30px 0;
  }
`

const ControlButton = styled.button<{ visible: boolean }>`
  display: none;

  ${media.lg.min} {
    position: absolute;
    z-index: 2;
    border: 1px solid ${({ theme }) => theme.colors.black};
    width: 40px;
    height: 40px;
    background: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

    &[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
`

const SlideLeftButton = styled(ControlButton)`
  left: -60px;
  top: 50%;
  transform: translateY(-50%);

  img {
    transform: rotate(180deg);
  }
`

const SlideRightButton = styled(ControlButton)`
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
`

const SliderWrapper = styled.div`
  position: relative;
  padding: 20px 0 0 0;

  .swiper-slide {
    width: fit-content;
  }

  ${media.lg.min} {
    padding: 30px 0;
  }
`

const SlideImageWrapper = styled.div`
  width: 194px;
  height: 259px;
  cursor: pointer;

  ${media.lg.min} {
    width: auto;
    transition: 0.3s;

    img {
      z-index: -1;
    }

    &:hover {
      box-shadow: inset 0px 0px 0px 20px rgba(0, 0, 0, 1);
    }
  }
`

type Props = {
  slides: Array<{
    text: string
    image: {
      src: ImageDataLike
      alt: string
    }
  }>
}

const AccessoryInstallationGallery: React.FC<Props> = ({ slides }) => {
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  const [modalGallery, setModalGallery] = useState({
    visible: false,
    activeIndex: 0,
    speed: 850,
  })

  const handleImageClick = (index: number) => {
    setModalGallery({ visible: true, activeIndex: index, speed: 0 })
    setTimeout(
      () =>
        setModalGallery({
          visible: true,
          activeIndex: index,
          speed: 850,
        }),
      100
    )
  }

  const closeModal = () => {
    setModalGallery({
      ...modalGallery,
      visible: false,
    })
  }

  return (
    <Container slim>
      <Wrapper>
        <HeadingWrapper>
          <Text
            size={26}
            weight={700}
            transform="uppercase"
            line="100%"
            margin="0"
          >
            Schemat instalacji produktu
          </Text>
        </HeadingWrapper>
        <SliderWrapper>
          <SlideLeftButton
            onClick={goPrev}
            disabled={activeIndex === 0}
            visible={slides.length > 4}
          >
            <Icon src={arrowIcon} />
          </SlideLeftButton>
          <SlideRightButton
            onClick={goNext}
            disabled={activeIndex + 3 === slides.length - 1}
            visible={slides.length > 4}
          >
            <Icon src={arrowIcon} />
          </SlideRightButton>
          <Swiper
            onSwiper={(initSwiper: SwiperInstance) => setSwiper(initSwiper)}
            spaceBetween={10}
            slidesPerView="auto"
            grabCursor
            onSlideChange={() => setActiveIndex(swiper?.realIndex ?? 0)}
            breakpoints={{
              992: {
                slidesPerView: 4,
              },
            }}
          >
            {slides.map(({ image }, index) => (
              <SwiperSlide key={`${image.alt}-${index}`}>
                <SlideImageWrapper onClick={() => handleImageClick(index)}>
                  <LazyImage src={image.src} alt={image.alt} fill />
                </SlideImageWrapper>
              </SwiperSlide>
            ))}
          </Swiper>
        </SliderWrapper>
      </Wrapper>
      <ModalGallery
        close={closeModal}
        slides={slides}
        activeIndex={modalGallery.activeIndex}
        visible={modalGallery.visible}
        speed={modalGallery.speed}
        updateIndex={(index) =>
          setModalGallery({ ...modalGallery, activeIndex: index })
        }
      />
    </Container>
  )
}

export default AccessoryInstallationGallery
