import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import SwiperInstance from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import LazyImage from 'components/shared/lazy-image'
import Icon from 'components/shared/icon'
import { Text } from 'components/shared/typography'
import ButtonWithIcon from 'components/shared/button-with-icon'

import useOutsideClick from 'hooks/useOutsideClick'

import media from 'styles/media'
import 'swiper/css'

import arrowIcon from 'assets/icons/arrow-right-white.svg'
import closeIcon from 'assets/icons/cross.svg'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div<{ active: boolean }>`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 10;
  background: ${({ theme }) => theme.colors.black};
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;

  ${media.lg.min} {
    left: 46px;
    right: 46px;
  }

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const InnerWrapper = styled.div`
  padding: 20px;

  ${media.lg.min} {
    padding: 30px 100px;
  }
`

const SwiperWrapper = styled.div``

const CloseButtonWrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;

  ${media.lg.min} {
    padding-bottom: 30px;
  }
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  cursor: pointer;
`

const SlideImageWrapper = styled.div`
  height: 400px;
  width: 100%;

  ${media.lg.min} {
    height: 612px;
    max-height: 50svh;
  }
`

const InfoWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.lg.min} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const TextWrapper = styled.div`
  padding-top: 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.white};

  ${media.lg.min} {
    order: -1;
    border: none;
  }
`

const Controls = styled.div`
  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  transform: translateY(-50%);
  z-index: 11;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  margin-top: 170px;

  button {
    pointer-events: all;
  }

  ${media.lg.min} {
    margin-top: 0;
    left: 30px;
    right: 30px;
  }
`

const ControlButton = styled.button`
  position: absolute;
  z-index: 11;
  border: 1px solid ${({ theme }) => theme.colors.white};
  width: 40px;
  height: 40px;
  background: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
  }
`

const SlideLeftButton = styled(ControlButton)`
  img {
    transform: rotate(180deg);
  }
`

const SlideRightButton = styled(ControlButton)`
  right: 0;
`

type Props = {
  close: () => void
  slides: Array<{
    image: {
      src: ImageDataLike
      alt: string
    }
    text?: string
  }>
  activeIndex: number
  speed?: number
  visible: boolean
  updateIndex?: (value: number) => void
}

const ModalGallery: React.FC<Props> = ({
  close,
  slides,
  activeIndex,
  speed = 850,
  visible,
  updateIndex,
}) => {
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null)
  const sliderRef = useRef<HTMLDivElement>(null)

  useOutsideClick({ ref: sliderRef, handler: close, preventClass: 'prevent' })

  const goPrev = (e: React.MouseEvent) => {
    e.stopPropagation()
    swiper?.slidePrev()

    updateIndex?.(swiper?.realIndex!)
  }

  const goNext = (e: React.MouseEvent) => {
    e.stopPropagation()
    swiper?.slideNext()

    updateIndex?.(swiper?.realIndex!)
  }

  useEffect(() => {
    swiper?.slideToLoop(activeIndex)
  }, [activeIndex])

  return (
    <Wrapper active={visible}>
      <InnerWrapper ref={sliderRef}>
        <CloseButtonWrapper>
          <CloseButton onClick={close} aria-label="Zamknij galerię">
            <Icon src={closeIcon} />
          </CloseButton>
        </CloseButtonWrapper>
        <SwiperWrapper>
          {slides && (
            <Swiper
              onSwiper={(initSwiper: SwiperInstance) => setSwiper(initSwiper)}
              slidesPerView={1}
              grabCursor
              speed={speed}
              initialSlide={activeIndex}
              onSlideChange={(sw) => updateIndex?.(sw.realIndex)}
            >
              {slides.map(({ image: { src, alt } }, index) => (
                <SwiperSlide key={`${alt}-image${index}`}>
                  <SlideImageWrapper>
                    <LazyImage
                      src={src}
                      alt={alt || ''}
                      fill
                      objectFit="contain"
                    />
                  </SlideImageWrapper>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </SwiperWrapper>
        <InfoWrapper>
          <ButtonWithIcon icon="download">Pobierz plik</ButtonWithIcon>
          <TextWrapper>
            <Text
              themecolor="white"
              size={16}
              line="26px"
              dangerouslySetInnerHTML={{
                __html: slides[activeIndex]?.text || '',
              }}
            />
          </TextWrapper>
        </InfoWrapper>
      </InnerWrapper>
      <Controls>
        <SlideLeftButton
          onClick={goPrev}
          disabled={activeIndex === 0}
          className="prevent"
        >
          <Icon src={arrowIcon} />
        </SlideLeftButton>
        <SlideRightButton
          onClick={goNext}
          disabled={activeIndex === slides.length - 1}
          className="prevent"
        >
          <Icon src={arrowIcon} />
        </SlideRightButton>
      </Controls>
    </Wrapper>
  )
}

export default ModalGallery
