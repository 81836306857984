import React from 'react'
import styled from 'styled-components'

import { Text } from 'components/shared/typography'
import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazy-image'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div``

const HeadingWrapper = styled.div`
  padding: 80px 0 30px;
  border-bottom: 4px solid ${({ theme }) => theme.colors.black};

  ${media.lg.min} {
    padding: 30px 0;
  }
`

const InnerWrapper = styled.div`
  ${media.lg.min} {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    padding: 30px 0;
  }
`

const InfoWrapper = styled.div`
  padding: 30px 0;

  ${media.lg.min} {
    padding: 0;
  }
`

const ProductCodeWrapper = styled.div<{ hasLine: boolean }>`
  padding-bottom: 20px;
  border-bottom: ${({ theme, hasLine }) =>
    hasLine ? `1px solid ${theme.colors.black}` : 'none'};

  ${media.lg.min} {
    padding-bottom: 30px;
  }
`

const ProductFeaturesWrapper = styled.div`
  padding-top: 20px;

  ${media.lg.min} {
    padding-top: 30px;
  }
`

const FeaturesList = styled.ul`
  list-style-type: disc;

  li {
    margin: 0;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    overflow: hidden;
  }

  ${media.lg.min} {
    margin-left: 74px;
  }
`

type Props = {
  productCode: string
  image: { src: ImageDataLike; alt: string }
  features: Array<string>
}

const AccessorySpecifications: React.FC<Props> = ({
  productCode,
  image,
  features,
}) => {
  return (
    <Container slim>
      <Wrapper>
        <HeadingWrapper>
          <Text
            size={26}
            weight={700}
            transform="uppercase"
            line="100%"
            margin="0"
          >
            Specyfikacja
          </Text>
        </HeadingWrapper>
        <InnerWrapper>
          <InfoWrapper>
            <ProductCodeWrapper hasLine={!!features.length}>
              <Text
                size={26}
                line="100%"
                weight={700}
                margin="10px"
                transform="uppercase"
              >
                Kod produktu
              </Text>
              <Text
                size={16}
                margin="0"
                transform="uppercase"
                dangerouslySetInnerHTML={{ __html: productCode }}
              />
            </ProductCodeWrapper>
            {features.length > 0 && (
              <ProductFeaturesWrapper>
                <Text
                  size={26}
                  line="100%"
                  weight={700}
                  margin="10px"
                  transform="uppercase"
                >
                  Funkcje
                </Text>
                <FeaturesList>
                  {features.map((feature) => (
                    <li key={feature}>
                      <Text size={16} line="26px" margin="0">
                        {feature}
                      </Text>
                    </li>
                  ))}
                </FeaturesList>
              </ProductFeaturesWrapper>
            )}
          </InfoWrapper>
          <ImageWrapper>
            <LazyImage src={image.src} alt={image.alt} objectFit="contain" />
          </ImageWrapper>
        </InnerWrapper>
      </Wrapper>
    </Container>
  )
}

export default AccessorySpecifications
